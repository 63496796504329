import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { UserOptions } from '../interfaces/user-options';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { StorageService } from './storage.service';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);
  constructor(private storageService: StorageService, private plt: Platform, public http: HttpClient, public loadingController: LoadingController, public toastController: ToastController) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  async user() {
    return await this.storageService.get(TOKEN_KEY);
  }

  login(userOptions: UserOptions) {
    return this.storageService.set(TOKEN_KEY, userOptions).then(res => {
      this.authenticationState.next(true);
    });
  }

  logout() {
    return this.storageService.remove(TOKEN_KEY).then(res => {
      this.authenticationState.next(false);
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  checkToken() {
    return this.storageService.get(TOKEN_KEY).then(async res => {
      if (res) {
        this.authenticationState.next(false);
      }
    });
  }
}
